import React, { Component } from "react";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import logo from "../../images/junio_icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "../../css/custom.css";
import classNames from "classnames";
import NewAnim from "../../animation/new_anim.json";
import LottieAnimate from "./LottieAnimation";

class Header extends Component {
  state = {
    wWidth: null,
    isTop: true,
  };
  componentDidMount = () => {
    if (window !== undefined) {
      this.setState({ wWidth: window.innerWidth });
      window.addEventListener("resize", () => {
        this.setState({
          wWidth: window.innerWidth,
        });
      });
      document.addEventListener("scroll", () => {
        const isTop = window.scrollY < 40;
        if (isTop !== this.state.isTop) {
          this.setState({ isTop });
        }
      });
    }
  };
  handleStyle = () => {
    setTimeout(() => {
      if (document.getElementsByClassName("show").length > 0) {
        document.getElementById("navbar").style.backgroundColor = "#8A27F7";
      } else {
        document.getElementById("navbar").style.backgroundColor = "none";
      }
    }, 400);
  };
  goToBlog = () => {
    window.open("https://blog.junio.in/", "_blank");
  };
  goToVouchers = () => {
    window.open("/vouchers", "_blank");
  };
  render() {
    const { overrideStyle } = this.props;
    return (
      <Container
        fluid
        className="p-0"
        style={{
          position: "fixed",
          top: "0px",
          zIndex: 4,
          width: "100%",
          ...(overrideStyle?.container || {}),
        }}
      >
        <Navbar
          id="navbar"
          expand={this.state.wWidth >= 1400 ? true : false}
          className={classNames({
            "nav-down": !this.state.isTop,
            "c-pad": true,
          })}
          // expand="xl"
        >
          <Navbar.Brand
            href="/"
            style={{
              color: "white",
              fontWeight: "700",
              fontFamily: "Helvetica",
            }}
          >
            <img
              alt=""
              src={logo}
              height="26px"
              width="120px"
              style={{ marginRight: "28px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav navbar-dark"
            style={{ color: "white", borderColor: "white" }}
          >
            <FontAwesomeIcon
              icon={faBars}
              color="white"
              onClick={this.handleStyle}
            />
          </Navbar.Toggle>
          <Navbar.Collapse
            id="basic-navbar-nav"
            style={{
              color: "white",
              fontFamily: "Montserrat",
            }}
          >
            <Nav
              className="ml-auto"
              style={{ color: "white", fontSize: "normal", marginLeft: "auto" }}
            >
              <Nav.Link href="/#home" className="active">
                Home
              </Nav.Link>
              <Nav.Link href="/#features">Features</Nav.Link>
              <Nav.Link href="/partner-with-us">Partner with us</Nav.Link>
              <Nav.Link onClick={this.goToBlog}>Blog</Nav.Link>
              <Nav.Link href="/#faq">FAQs</Nav.Link>
              <Nav.Link
                className="n-link-faq"
                onClick={() => {
                  window.fcWidget.open();
                  window.fcWidget.show();
                }}
              >
                Get Help
              </Nav.Link>
              {/* <div
                className="brand_voucher_btn_container"
                onClick={this.goToVouchers}
              >
                <div className="d-flex flex-row justify-content-center brand_voucher_btn_lottie_container">
                  <div className="brand_voucher_btn_lottie_content">
                    <LottieAnimate
                      name={"lottie-anim-new-badge"}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: NewAnim,
                      }}
                      height={"100%"}
                      width={"100%"}
                    />
                  </div>
                </div>

                <Button
                  className="btn-sec"
                  style={{
                    borderRadius: "60px",
                    paddingInline: "16px",
                  }}
                >
                  Brand Vouchers
                </Button>
              </div> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  }
}

export default Header;
